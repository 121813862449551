import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import VisionCarousel from '../components/Vision Carousel';
const Contact = () => {
 let [successMessage, setSuccessMessage] = useState(false);
        let [errorMessage, setErrorMessage] = useState(false);
        let [sendingMessage, setSendingMessage] = useState(false);
        let formInput1 = useRef(null);
        let formInput2 = useRef(null);
        let formInput3 = useRef(null);
        let formInput4 = useRef(null);
        let formInput5 = useRef(null);

let sendFunc =()=>{
  setSendingMessage(true);
    setSuccessMessage(false);
    setErrorMessage(false);
  
}


  useEffect(()=>{
document.title = 'Write To Us | Asta Systems';

},[]);

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bp0kw6hBen', 'template_0qrcgko', form.current, 'DI6CQ1InICHT9S5Av')
      .then((result) => {
          // console.log(result.text);
          // console.log('Message Sent');
          if(result){
setSuccessMessage(true);
          setSendingMessage(false);

              setTimeout(() => {
            setSuccessMessage(false);
        }, 3000);
formInput1.current.value = '';
formInput2.current.value = '';
formInput3.current.value = '';
formInput4.current.value = '';
formInput5.current.value = '';

         }

      }, (error) => {
          // console.log(error.text);
           if (error){
setErrorMessage(true);
          setSendingMessage(false);
           setTimeout(() => {
           setErrorMessage(false);
        }, 3000);
formInput1.current.value = '';
formInput2.current.value = '';
formInput3.current.value = '';
formInput4.current.value = '';
formInput5.current.value = '';

          }
      });
  };
  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/5.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Get In Touch With Us</h2>
            
        </div>
    </section>
    <section className='section-cover contact-cover' >
      {/* <h2 className="home-h2 ">We would love to hear from you!</h2> */}
         {/* <p className='home-p home-p1'>We would love to hear from you!</p> */}

         <div className='content-container contact-section'>
       
<div className='form-cover'>
  {/* <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Contact Number</label>
      <input type="number" name="user_number" />
      <label>Subject</label>
      <input type="text" name="user_subject" />


      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */}
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" ref={formInput1}/>
      <label>Email</label>
      <input type="email" name="user_email" ref={formInput2}/>
      <label>Contact Number</label>
      <input type="text" name="user_number" ref={formInput3}/>
      <label>Subject</label>
      <input type="text" name="user_subject" ref={formInput4} />


      <label>Message</label>
      <textarea name="message" ref={formInput5}/>
       <div  className={`${sendingMessage?'success-box':'hide'}`}>Sending...</div>
      <div  className={`${successMessage?'success-box':'hide'}`}>Thank you! Your message has been sent.</div>
      <div className={`${errorMessage?'error-box':'hide'}`}>Oh, sorry! An error occurred.</div>
      <input type="submit" value="Send Message" onClick={sendFunc} />
    </form>
</div>
           <div className='contact-slides'> 
{/* <VisionCarousel></VisionCarousel> */}
<img src='./images/3.jpg' className='vision-image'></img>
</div>

         </div>

    </section>
    </>
  )
}

export default Contact